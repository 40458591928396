import logo from './images/logo_full.png';
import './App.css';

import MobileStoreButton from 'react-mobile-store-button';

function App() {
    const iOSUrl = process.env.REACT_APP_IOS_URL;
    const androidUrl = process.env.REACT_APP_ANDROID_URL;
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Blindly is a new kind of dating app, geared towards deeper connections.
                </p>
                <p>
                    We aim to encourage meaningful conversations, consideration and authenticity.
                </p>
              <p/>
                <div>
                    <MobileStoreButton
                        store="ios"
                        url={iOSUrl}
                        linkProps={{title: 'If you have an iPhone'}}
                    />
                  &nbsp;&nbsp;
                    <MobileStoreButton
                        url={androidUrl}
                        store="android"
                        linkProps={{title: 'If you have an Android phone'}}
                    />
                </div>
            </header>
        </div>
    );
}

export default App;
